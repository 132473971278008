import React from 'react'
import { cryptInfo } from '../config'
import { Upload } from '../components/payslips/Uploads'
import GenerateSlip from '../components/payslips/GenerateSlip'
import SideBar from '../components/content/Sidebar'
import Content from '../components/content/Content'
import { Button, Col, Container, Row } from 'reactstrap'
import { ToastContainer } from 'react-toastify'

const PayslipsContainer = () => {
	const isAdmin = cryptInfo.decrypt(localStorage.getItem('role')) === 'admin' || cryptInfo.decrypt(localStorage.getItem('role')) === 'manager'
	const [isOpen, setIsOpen] = React.useState(true)
	const toggle = () => setIsOpen(!isOpen)
	const [timings, setTimings] = React.useState("00:00:00")

	return (
		<div className="App wrapper">
			<SideBar toggle={toggle} isOpen={isOpen} />
			<Content toggle={toggle} isOpen={isOpen} setTimings={setTimings}>
				<Container fluid>
					<Row className="page-heading">
						<Col className="text-left">
							<span>Generate Payslips</span>
						</Col>
					</Row>
				</Container>
				<Container fluid>
					<Row className="mt-5 mx-0">
						<GenerateSlip isAdmin={isAdmin} />
					</Row>
				</Container>
			</Content>
			<ToastContainer />
		</div>
	)
}

export default PayslipsContainer;