import { HTTP_METHODS, callApi } from './callApi'
import { API_CALL } from '../../src/config'
const apiRoot = `${API_CALL}/api/v1`

export const uploadPayslip = (data) => {
  const url = `${apiRoot}/payslips`
  let method = HTTP_METHODS.POST
  let body = data
  return callApi(url, method, body)
}

export const getAllPaySlips = (month, year) => {
  const url = `${apiRoot}/payslips?month=${month}&year=${year}`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}

export const getAllPaySlipsOfEmployee = (year) => {
  const url = `${apiRoot}/payslips//user-payslips?year=${year}`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}

export const generateSlips = (data) => {
  const url = `${apiRoot}/salary-slips/generate-slips`
  let method = HTTP_METHODS.POST
  let body = data
  return callApi(url, method, body)
}
