import React, { Component } from 'react'
import { Container, Row, Col, Button, FormGroup, Input, Label, Alert } from 'reactstrap'
import { Redirect } from 'react-router-dom'
import { createToken, updatePassword } from '../middleware/forgot-password'
import image from '../static/images/gten.png'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

class ForgotPasswordPageContainer extends Component {

	state = {
		isSignedIn: localStorage.getItem('isSignedin') || false,
		error: null,
		password: "",
		confirmPassword: "",
		emailId: "",
		message: null
	}

	setPassword = (password) => {
		this.setState({ password })
	}

	setEmail = (emailId) => {
		this.setState({ emailId })
	}

	setConfirmPassword = (confirmPassword) => {
		const { password } = this.state
		if (password !== confirmPassword)
			this.setState({ error: "Confirm password doesn't match with password." })
		else
			this.setState({ error: null })
		this.setState({ confirmPassword })
	}

	onSubmit = () => {
		const { password } = this.state
		const { userId, token } = this.props
		if (password.length < 8 || password.length === 0) {
			this.setState({ error: 'Password length is not valid.' })
			return
		}

		let values = {
			password
		}

		updatePassword(userId, token, values)
			.then(async (res) => {
				const result = await res.json()
				if (result.error) {
					this.setState({ error: result.message })
					return
				} else {
					toast.success('Your password has been successfully updated. Please wait we are redirecting you to login page.')
					window.setTimeout(() => {
						window.location.href = '/login'
					}, 1500)
				}
			})
	}

	onLinkGenerate = async () => {
		const { emailId } = this.state
		if (!emailId) {
			this.setState({ error: 'Please enter a valid email id.' })
			return
		}
		await createToken({ email: emailId })
			.then(async (res) => {
				const result = await res.json()
				if (!result.error) {
					this.setState({ message: result.message})
					toast.success(result.message)
				} else {
					this.setState({ error: result.message })
				}
			})
		window.setTimeout(() => {
			this.setState({ error: null, message: null,emailId:"" })
		}, 4000)
	}

	render() {
		const { isSignedIn, password, confirmPassword, emailId } = this.state
		const { userId, token } = this.props
		if (isSignedIn) {
			return <Redirect to="/welcome" />
		}
		return (
			<Container fluid className="p-5">
				<div className="form-center-register">
					<Container fluid>
						<Row>
							<Col className="text-center">
								<img src={image} width="200" alt="Not Found" />
							</Col>
						</Row>
					</Container>
					<div className="headline center">
						<p className="tagline">Forgot Password</p>
					</div>
					<Container fluid>

						{!userId && !token && <Row>
							<Col>
								<FormGroup>
									<Label>Email <span style={{ fontSize: '9px' }}>*Please enter the email associated with your GTEN account then you will be emailed a link to reset your password.</span></Label>
									<Input
										type="email"
										value={emailId}
										onChange={(event) => this.setEmail(event.target.value)}
									/>
								</FormGroup>
							</Col>
						</Row>}

						{(userId && token) &&
							<>
								<Row>
									<Col>
										<FormGroup>
											<Label>Password <span style={{ fontSize: '10px' }}>(*Password length must be equat to greater than 8 characters.)</span></Label>
											<Input
												type="password"
												value={password}
												minLength={8}
												onChange={(event) => this.setPassword(event.target.value)}
											/>
										</FormGroup>
									</Col>
								</Row>
								<Row>
									<Col>
										<FormGroup>
											<Label>Confirm Password</Label>
											<Input
												type="password"
												value={confirmPassword}
												minLength="8"
												onChange={(event) => this.setConfirmPassword(event.target.value)}
											/>
										</FormGroup>
									</Col>
								</Row>
							</>
						}
						<Row>
							<Col>
								{this.state.error && <Alert color="danger">
									{this.state.error}
								</Alert>}
								{this.state.message && <Alert color="success">
									{this.state.message}
								</Alert>}
							</Col>
						</Row>
						<Row>
							{!userId && !token &&
								<Col className="text-center">
									<Button color="primary" onClick={this.onLinkGenerate}>
										Submit
									</Button>
								</Col>}
							{
								userId && token &&
								<Col className="text-center">
									<Button color="primary" onClick={this.onSubmit}>
										Submit
									</Button>
								</Col>
							}
						</Row>
					</Container>
					<Container fluid>
						<Row>
							<Col className="text-center text-lg">
								Or
							</Col>
						</Row>
						<Row>
							<Col className="text-center">
								<a href="/login">click here to login</a>
							</Col>
						</Row>
					</Container>
					<ToastContainer />
				</div>
			</Container>

		)
	}
}

export default ForgotPasswordPageContainer
