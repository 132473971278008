import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import LeaveForm from './leaves/LeaveForm'
import { Form } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import arrayMutators from 'final-form-arrays'
import { leaveData } from '../data/leaveTypeData'
import { createLeave, updateLeave, sendMailToAdmin } from '../middleware/api'
import { getDates } from '../data/dataFromApis'
import { cryptInfo } from '../config'
import { Input } from 'reactstrap'

class ApplyLeaveModal extends React.Component {
    state = {
        leave: null,
        minDate: "",
        user: this.props.user,
        useTeamMember: false
    }

    componentDidMount() {
        this.minDate()
    }

    minDate = () => {
        let today = new Date(),
            day = today.getDate(),
            month = today.getMonth() + 1, //January is 0
            year = today.getFullYear();
        if (day < 10) {
            day = '0' + day
        }
        if (month < 10) {
            month = '0' + month
        }
        today = year + '-' + month + '-' + day
        this.setState({
            minDate: today
        })
    }

    checkClLeaves = (values) => {
        let leaveCount = 0;
        const { leaves } = this.props;

        // Filter leaves by status and type (casual) and check for date overlaps
        leaves && leaves
            .filter(leave => leave.status !== 'Cancelled' && leave.type === 'casual')
            .forEach(leave => {
                let startDate = new Date(leave.from); // Existing leave's start date
                let endDate = new Date(leave.to);     // Existing leave's end date
                let leaveStartDate = new Date(values.from); // New leave's start date
                let leaveEndDate = new Date(values.to);     // New leave's end date

                // Log the dates and comparison values
                console.log("Existing leave start date:", startDate);
                console.log("Existing leave end date:", endDate);
                console.log("New leave start date:", leaveStartDate);
                console.log("New leave end date:", leaveEndDate);

                console.log("Existing leave month and year (start):", startDate.getMonth(), startDate.getFullYear());
                console.log("Existing leave month and year (end):", endDate.getMonth(), endDate.getFullYear());
                console.log("New leave month and year (start):", leaveStartDate.getMonth(), leaveStartDate.getFullYear());
                console.log("New leave month and year (end):", leaveEndDate.getMonth(), leaveEndDate.getFullYear());

                // Check if the leaves are in the same month and year
                const sameMonthAndYear =
                    (startDate.getMonth() === leaveStartDate.getMonth() &&
                        startDate.getFullYear() === leaveStartDate.getFullYear()) &&
                    (endDate.getMonth() === leaveEndDate.getMonth() &&
                        endDate.getFullYear() === leaveEndDate.getFullYear());

                // Log whether the months and years match
                console.log("Same month and year:", sameMonthAndYear);

                // If the dates fall in the same month and year, add the leave count
                if (sameMonthAndYear && values.count > 0) {
                    console.log("Adding leave count:", leave.count);
                    leaveCount += parseFloat(leave.count);
                }
            });

        // Log the total leave count after processing
        console.log("Total leave count:", leaveCount);
        return leaveCount;
    }



    onSubmit = async (values) => {

        let { fetchLeaves, leave, toggle, holidays, user, isManager, refreshList, setError } = this.props
        let { useTeamMember } = this.state
        if (useTeamMember) {
            user = this.state.user
        }
        const userId = user._id
        const email = user.email
        values.status = "Pending"
        values.user = userId
        values.email = email
        values.leavebalanceCL = user.leavebalanceCL
        values.leavebalanceEL = user.leavebalanceEL
        var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        var firstDate = new Date(values.from)
        var currentYear = new Date().getFullYear()
        var fromDay = firstDate.getDay()
        var fromYear = firstDate.getFullYear()
        var secondDate = new Date(values.to)
        var toDay = secondDate.getDay()
        var toYear = secondDate.getFullYear()
        // console.log(currentYear,fromYear,toYear)
        // return
        if (values.type === 'casual' && (fromYear > currentYear || toYear > currentYear)) {
            return { [FORM_ERROR]: "Please use EL, as CL for next year are yet to be credited." }
        }
        var difference = firstDate.getTime() - secondDate.getTime()
        if (difference > 0) {
            return { [FORM_ERROR]: "Please enter valid dates. We are getting a negative or zero days count." }
        }
        var diffDays = Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / (oneDay)))

        var weeks = Math.floor(diffDays / 7);
        diffDays = diffDays - (weeks * 2);
        if (fromDay - toDay > 0) {
            diffDays = diffDays - 2;
        }
        // Remove start day if span starts on Sunday but ends before Saturday
        if (fromDay === 0 && toDay !== 6) {
            diffDays = diffDays - 1
        }
        // Remove end day if span ends on Saturday but starts after Sunday
        if (toDay === 6 && fromDay !== 0) {
            diffDays = diffDays - 1
        }
        var dates = getDates(firstDate, secondDate);
        dates.forEach(function (date) {
            if (date.getDay() !== 0 && date.getDay() !== 6) {
                var year = date.getFullYear()
                var month = date.getMonth() + 1
                var day = date.getDate()
                if (month < 10) {
                    month = `0${month}`
                }
                if (day < 10) {
                    day = `0${day}`
                }
                var curDate = [year, month, day].join('-')
                if (holidays.indexOf(curDate) !== -1) {
                    diffDays = diffDays - 1
                }
            }
        })
        values.count = diffDays + 1

        if (values.halfday) {
            values.count = 0.5
        }

        // var leaveCount = values.type === 'casual' ? this.checkClLeaves(values) : 0

        // if (leaveCount >= 1) {
        //     return { [FORM_ERROR]: "You cannot apply more than 1 Casual leave in a month." }
        // }
        // // return
        // if (values.type === 'casual' && ((leaveCount === 0 && values.count > 1))) {
        //     return { [FORM_ERROR]: "You cannot apply more than 1 Casual leave in a month." }
        // }

        // if (values.count > 1 && values.type === 'casual') {
        //     return { [FORM_ERROR]: "You cannot apply more than 1 Casual leave in a month." }
        // }

        if (values.count > values.leavebalanceCL && values.type === 'casual') {
            return { [FORM_ERROR]: "You don't have sufficient Casual leave balance, please choose LWP as an leave type." }
        }

        if (values.count > user.leavebalanceEL && values.type === 'earned') {
            return { [FORM_ERROR]: "You don't have sufficient Earned leave balance, please choose LWP as an leave type." }
        }

        var msg = `You have ${user.leavebalanceEL} leaves as balance, do you want to apply rest as LWP. Click on 'OK' to confirm and 'Cancel' to revise your leaves`
        if (values.count > user.leavebalanceEL && values.type === 'earned') {
            var cnfrm = window.confirm(msg)
        }

        if (!cnfrm && cnfrm === false) {
            return { [FORM_ERROR]: "Please revise your leave selection" }
        }

        if (values.count === 0) {
            return { [FORM_ERROR]: "You can't apply for 0 days OR the selected day is either a holiday or weekend." }
        }

        if (leave && leave._id) {
            await updateLeave(leave._id, values)
                .then(async (response) => {
                    await fetchLeaves()
                    if (isManager)
                        await refreshList()
                    toggle()
                })
        } else {
            await createLeave(values)
                .then(async (response) => {
                    var result = await response.json()
                    if (result && result.status && result.success === false) {
                        setError(result.status)
                        return { [FORM_ERROR]: "You already have applied for leave for the selected date." }
                    } else {
                        await fetchLeaves()
                        toggle()
                        setError(null)
                        if (isManager)
                            await refreshList()
                        sendMailToAdmin({
                            subject: "Leave Application",
                            sender: cryptInfo.decrypt(localStorage.getItem('email')),
                            type: leaveData[values.type],
                            reason: values.reason,
                            from: values.from,
                            to: values.to,
                            user: userId,
                            message: `Hello , ${isManager ? values.empname : localStorage.getItem('fname')} has applied for ${values.count} day(s) leave(s). ${isManager ? `Leave(s) is/are applied by ${localStorage.getItem('fname')}. ` : ''}`
                        })
                    }
                })

                .catch(err => {
                    return { [FORM_ERROR]: "You already have applied for leave for the selected date OR Something went wrong !!!" }
                })

        }

    }

    onUserChange = (e) => {
        const { teamMember } = this.props
        let selectedUser = teamMember && teamMember.find(t => t._id === e.target.value)
        this.setState({
            user: selectedUser,
            useTeamMember: true
        })
    }

    reactSelectAdapter = ({ input: { onChange, value }, ...rest }) => {
        const { teamMember } = this.props
        let teamMemberList = teamMember && teamMember.map((t) => {
            return { value: t._id, label: t.fname }
        })
        return (<Input type='select' onChange={this.onUserChange}>
            <option key='hello'>Select</option>
            {teamMemberList.map((list) => {
                return <option key={list.value} value={list.value}>{list.label}</option>

            })}
        </Input>
        )
    }

    render() {

        let { isOpen, toggle, leave, user, className, teamMember, formError } = this.props
        let { useTeamMember } = this.state
        if (useTeamMember) {
            user = this.state.user
        }
        const { minDate } = this.state
        const modalTitle = leave ? "Edit Leave" : "Apply Leave"
        let teamMemberList = teamMember && teamMember.map((t) => {
            return { value: t._id, label: t.fname }
        })

        return (
            <div>
                <Modal id="project-modal" isOpen={isOpen} toggle={toggle} className={className}>
                    <ModalHeader
                        tag="h2"
                        toggle={toggle}>{modalTitle}</ModalHeader>
                    <ModalBody>
                        <Form
                            component={LeaveForm}
                            formId="addleave"
                            toggle={toggle}
                            onUserChange={this.onUserChange}
                            isManager={cryptInfo.decrypt(localStorage.getItem('role')) === 'manager'}
                            isAdmin={cryptInfo.decrypt(localStorage.getItem('role')) === 'admin'}
                            teamMember={teamMemberList}
                            reactSelectAdapter={this.reactSelectAdapter}
                            onSubmit={this.onSubmit}
                            subscription={{
                                pristine: true,
                                submitError: true,
                                submitting: true,
                                hasValidationErrors: true,
                                values: true
                            }}
                            minDate={minDate}
                            mutators={{
                                ...arrayMutators,
                            }}
                            formError={formError}
                            update={leave}
                            initialValues={
                                {
                                    empname: user && user.fname + ' ' + user.lname,
                                    pendingEL: user && (user.leavebalanceEL === null ? 0 : user.leavebalanceEL),
                                    pendingCL: user && (user.leavebalanceCL === null ? 0 : user.leavebalanceCL),
                                    to: leave && leave.to ? leave.to : "",
                                    from: leave && leave.from ? leave.from : "",
                                    type: leave && leave.type ? leave.type : "",
                                    count: leave && leave.count ? leave.count : "",
                                    reason: leave && leave.reason ? leave.reason : "",
                                    status: leave && leave.status ? leave.status : "",
                                }
                            }
                        />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default ApplyLeaveModal;