import React, { useRef, useState } from "react";
import { Button } from "reactstrap";

const FileUpload = ({
  onUpload,
  file,
  setFile,
  csvData,
  selectedFile,
  setSelectedFile,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [error, setError] = useState(null);
  const fileInputRef = useRef(null);

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const files = Array.from(e.dataTransfer.files).filter(
      (file) => file.type === "text/csv"
    );
    if (files.length === 0) {
      setError("Please upload a CSV file only.");
      return;
    }
    handleFiles(files.slice(0, 1)); // Limit to one file
  };

  const handleFiles = (files) => {
    setError(null);
    setSelectedFile(files[0]);
    setTimeout(() => {
      // Simulate file upload time
      onUpload(files[0]);
    }, 1000);
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files).filter(
      (file) => file.type === "text/csv"
    );
    if (files.length === 0) {
      setError("Please upload a CSV file only.");
      return;
    }
    handleFiles(files.slice(0, 1)); // Limit to one file
  };

  const handleChooseFiles = () => {
    fileInputRef.current.click();
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    setError(null);
    fileInputRef.current.value = null;
    setFile(null);
    handleChooseFiles();
  };

  return (
    <div>
      {selectedFile || file ? (
        <div
          style={{
            border: "2px dashed #cccccc",
            padding: "20px",
            textAlign: "center",
            marginBottom: "20px",
            backgroundColor: "#f8f9fa",
            position: "relative",
          }}
        >
          <p>{selectedFile?.name || file?.name}</p>
          <Button
            color="primary"
            onClick={handleRemoveFile}
            style={{ textDecoration: "none" }}
          >
            Change File
          </Button>
          {csvData && (
            <p className="p-2 mt-2">
              Your selected file has {csvData.length} records.
            </p>
          )}
        </div>
      ) : (
        <div
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onClick={handleChooseFiles}
          style={{
            border: isDragging ? "2px dashed #007bff" : "2px dashed #cccccc",
            padding: "20px",
            textAlign: "center",
            marginBottom: "20px",
            cursor: "pointer",
            backgroundColor: isDragging ? "#f8f9fa" : "transparent",
          }}
        >
          <p>Drag & drop or click to select a CSV file</p>
        </div>
      )}

      <input
        type="file"
        accept=".csv"
        onChange={handleFileChange}
        style={{ display: "none" }}
        ref={fileInputRef}
      />
      {error && <p style={{ color: "red" }}>{error}</p>}
      {csvData && csvData.length === 0 && (
        <p style={{ color: "red" }}>Selected CSV has zero items</p>
      )}
    </div>
  );
};

export default FileUpload;
