import React, { useState } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import FileUpload from "./FileUpload";
import Papa from "papaparse";
import { generateSlips } from "../../middleware/payslips";
import { toast } from "react-toastify";

const GenerateSlip = ({ isAdmin }) => {
  const [file, setFile] = useState(null);
  const [csvData, setCsvData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileUpload = (file) => {
    if (!file) {
      setError("No file selected");
      return;
    }

    if (file.type !== "text/csv") {
      setError("Please upload a valid CSV file");
      return;
    }

    setError(null);

    Papa.parse(file, {
      header: true, // Converts rows to objects using the first row as keys
      skipEmptyLines: true, // Skips empty lines
      complete: (result) => {
        console.log(result.data)
        const finalData = parseAndFormatData(result.data);
        setCsvData(finalData); // Resulting array of objects
      },
      error: (error) => {
        setError(error.message);
      },
    });
  };

  const parseAndFormatData = (data) => {
    return data.map((item) => {
      const formattedObject = {};

      Object.entries(item).forEach(([key, value]) => {
        const camelCaseKey = key
          .replace(
            /\((.*?)\)/g,
            (_, content) => content.charAt(0).toUpperCase() + content.slice(1)
          )
          .toLowerCase()
          .replace(/(\s+|_)(.)/g, (_, __, char) =>
            char ? char.toUpperCase() : ""
          )
          .replace(/[^a-zA-Z0-9]/g, "");

        formattedObject[camelCaseKey] = value;
      });

      return formattedObject;
    });
  };

  const handleSlips = async () => {
    setLoading(true)
    try {
     const response = await generateSlips(csvData);
     const result = await response.json()
     if(result.success === false){
      toast.error(result.message)
     } else{
      toast.success("Payslips generated and emails sent successfully.")
      setFile(null)
      setSelectedFile(null)
      setCsvData(null)
     }
    } catch (error) {
      toast.error("Something went wrong")
      setError(error);
    } finally {
      setLoading(false)
    }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <FileUpload
              onUpload={handleFileUpload}
              file={file}
              setFile={setFile}
              csvData={csvData}
              error={error}
              loading={loading}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
            />
          </Col>
        </Row>
        {csvData && csvData.length > 0 && (
          <Button color="primary" onClick={handleSlips}>
           {loading ? "Generating..." : "Click to Generate Payslips"} 
          </Button>
        )}
      </Container>
    </>
  );
};

export default GenerateSlip;
